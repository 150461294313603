import SubTitle from 'components/elements/SubTitle'
import TitleDefault from 'components/elements/TitleDefault'
import React from 'react'
import { Helmet } from 'react-helmet'

interface InstaFeedProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_InstaFeed
}

const InstaFeed: React.FC<InstaFeedProps> = ({ fields }) => (
  <>
    <Helmet>
      <script src="https://apps.elfsight.com/p/platform.js" defer />
    </Helmet>
    <section id="instagramFeed" className="py-4 py-lg-5">
      <div className="container py-lg-5">
        <div className="d-flex flex-wrap">
          <TitleDefault>{fields?.title}</TitleDefault>
          <SubTitle className="mb-4 mb-lg-5">{fields?.subtitle}</SubTitle>

          <div className="col-12 px-0">
            {fields.elfsight ? (
              <div className={fields?.elfsight} />
            ) : (
              <div>Instagram feed niet gevonden...</div>
            )}
          </div>
        </div>
      </div>
    </section>
  </>
)

export default InstaFeed
